.header {
  padding: 1rem;
  background-color: #f7f7f7;
  position: fixed;
  width: 100%;
  z-index: 111;
  top: 0;
}
.main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.web-logo {
  width: 85px;
}
.logo img {
  cursor: pointer;
}
.search-city-fields {
  display: flex;
  align-items: center;
  gap: 30px;
}
.cart-log-lang {
  display: flex;
  align-items: center;
  gap: 30px;
}
.cart-field {
  position: relative;
}
.cart-hamberg {
  display: none;
}

.search_icon {
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  cursor: pointer;
}
.ar_search_icon {
  position: absolute;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.search_input {
  padding-left: 15px;
  cursor: pointer;
}
.search_input::placeholder {
  position: absolute;
  margin-left: 20px;
}
#en_left {
  padding-left: 35px;
}
#en_left::placeholder {
  margin-left: 5px;
}
#ar_right {
  padding-right: 35px;
}
#ar_right::placeholder {
  margin-right: 5px;
}
.location_button {
  border: 1px solid rgb(222, 216, 216) !important;
  border-radius: 5px;
}
.location_button:hover {
  background-color: #fff !important;
}

.login_btn {
  border: 1px solid rgb(222, 216, 216) !important;
  border-radius: 5px;
}
.country_btn {
  border: 1px solid rgb(222, 216, 216) !important;
  border-radius: 5px;
}
.logout {
  border: 0;
  font-size: 25px;
  color: rgb(48, 48, 117);
  appearance: none;
}
.ar_logout {
  border: 0;
  font-size: 20px;
  color: rgb(48, 48, 117);
  appearance: none;
}
#logout_op {
  width: 10px;
  position: absolute;
  top: 24px !important;
}

.myprofile {
  display: flex;
  gap: 20px;
  align-items: center;
}
.name_mobile {
  display: flex;
  flex-direction: column;
  line-height: 5px;
}
.profile_option {
  padding: 5px;
  cursor: pointer;
}
.md_item_count,
.ar_md_item_count,
.ar_md_logout,
.md_logout,
.lg_user,
.md_userLogin,
.ar_md_userLogin,
.ar_md_user,
.ar_md_location-controls,
.ar_sm_location-controls,
.sm_location-controls,
.md_country_btn,
.md_search_icon,
.md_cart_icon {
  display: none;
}
.en_cart,
.ar_cart,
.hamberg {
  font-size: 25px;
  color: rgb(48, 48, 117);
  cursor: pointer;
}
.cart-count {
  position: absolute;
  background: red;
  top: -10px;
  right: 10px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.ar_cart-count {
  position: absolute;
  background: red;
  top: -8px;
  left: 12px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#hide {
  display: none;
}
/* .location-controls */
.location-controls {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  width: 8.5rem;
  position: relative;
}
.location-controls button {
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  width: 100%;
  padding: 0.4rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.loc-container {
  width: 8rem;
  position: absolute;
  top: 2.5rem;
  border-radius: 0.3rem !important;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
.ar_loc-container {
  width: 8rem;
  position: absolute;
  top: 2.5rem;
  border-radius: 0.3rem !important;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
.dropdown-loc p {
  margin: 0;
  padding: 0.3rem 0;
}
.dropdown-loc {
  width: 100%;
  padding: 0.3rem 0.4rem;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-bottom: 1px dotted #9f9f9f;
}

.dropdown-loc:last-child {
  border: 0;
}
.options-loc {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.left_loc-align {
  left: 1rem;
}
.right_loc-align {
  right: 1rem;
}
.md_location-controls {
  display: none;
}
/* country language dropdown-list */
.form-controls {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  width: 5.5rem;
  position: relative;
}

.form-controls button {
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  width: 100%;
  padding: 0.34rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.form-controls button img,
.dropdown-list img {
  width: 1.5rem;
  height: 1rem;
}
.options-lists {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.15rem;
}

.dropdown-list {
  width: 100%;
  padding: 0.3rem 0.4rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  position: relative;
  cursor: pointer;
}

.dropdown-list:hover {
  background-color: #e7f5ff;
}

.dropdown-list p {
  position: absolute;
  top: 0;
}

.left-align {
  left: 2.4rem;
}

.right-align {
  right: 2.4rem;
}

.select-dropdown-icon {
  font-size: 0.8rem;
}

.globe-image,
.ar_md_search_icon {
  display: none;
}
.md-device-hide {
  display: none;
}
#ar_md-device-show {
  display: none;
}
.md-search-fields {
  display: none;
}
@media (max-width: 992px) {
  .content-modal {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0.5px);
  }
  .cart-log-lang,
  .city-fields {
    display: none;
  }
  .md-city-fields {
    display: block;
  }

  .cart-field {
    display: flex;
    justify-content: end;
  }
  .cart-hamberg {
    display: flex;
    align-items: center !important;
    gap: 30px;
  }
  .md-device-show {
    z-index: 11;
    position: fixed !important;
    display: flex;
    flex-direction: column;
    right: 0;
    top: 75px;
    background-color: #f7f7f7;
    padding: 2rem;
  }

  .dropdown-list {
    width: 150%;
    padding: 0.3rem 0.4rem;
    align-items: center;
    position: relative;
    cursor: pointer;
    border-bottom: 1px dotted #9f9f9f;
  }
  .location-controls button {
    border: none;
    border-radius: 0.3rem;
    width: 100%;
    padding: 0.4rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .form-controls button {
    border: none;
    border-radius: 0.3rem;
    width: 100%;
    padding: 0.34rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  #ar_md-modal {
    left: 0;
    right: auto;
  }
}
@media (max-width: 768px) {
  .search-filds {
    width: 280px;
  }
  .md-device-show {
    z-index: 20;
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0;
    top: 75px;
    background-color: #f7f7f7;
    padding: 2rem;
  }

  #ar_md-modal {
    left: 0 !important;
    right: auto;
  }
  /* .subheader {
    font-size: 25px;
  } */
  .dropdown-list {
    width: 150%;
    padding: 0.3rem 0.4rem;
    align-items: center;
    position: relative;
    cursor: pointer;
    border-bottom: 1px dotted #9f9f9f;
  }
}
@media (max-width: 576px) {
  .search-filds {
    display: none;
  }
  .subheader {
    font-size: 20px;
  }
  .md-search-fields,
  .md_search_icon,
  .ar_md_search_icon {
    display: block;
    font-size: 25px;
    color: rgb(48, 48, 117);
    cursor: pointer;
  }
}
