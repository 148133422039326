.hero {
    color: #25657E;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Onest', sans-serif;
  }
  .hero h2 {
    /* margin: 0 auto; */
    font-weight: 900;
    font-size: 150px;
    line-height: 100%;
    text-transform: uppercase;
  }
  .text-bg-image {
    background-image:  url('../../assets/images/error.jpg');
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color:  #ffffff;
    animation: animate-bg 5s ease infinite;
  }
  
  .hero p {
    margin: 0 auto;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
  }
  
  @keyframes animate-bg {
    0% {
      background-position: center 25%;
    }
    50% {
      background-position: center 50%;
    }
    100% {
      background-position: center 25%;
   }
  }