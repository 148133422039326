/* common starts */
.sticky-top {
  position: sticky !important;
  top: 0;
  z-index: 0 !important;
}
.allpages {
  margin-top: 90px;
}
.text-color {
  color: rgb(48, 48, 117);
}
.bg-overlay {
  position: absolute;
  background-size: cover !important;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
}
/* common closed */

.logo {
  max-width: 85px;
  cursor: pointer;
}
.logo img {
  width: 85px;
  margin-left:15px;
}
#show {
  position: absolute;
  min-width: 6rem;
  top: 10%;
  cursor: pointer;
}

/* .about */
.bannerhead {
  background-image: url("../images/about.webp");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 180px;
}

.service_banner {
  position: relative;
  overflow: hidden;
  padding: 180px;
}
.service-banner {
  height: 200px;
}
.service-banner img {
  height: 200px;
  width: 100%;
  border-radius: 7px;
}
.service_data {
  margin-top: 10px;
  line-height: 5px;
}
.bannertext {
  position: relative;
  z-index: 11;
}

/*abou page */
.aboutPage,
.contactPage {
  margin-top: 72px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}
.aboutPage h1 {
  font-weight: bold;
}
.aboutCards {
  background-color: rgb(48, 48, 144);
  border-radius: 5px;
}
.abt_banner {
  width: 100%;
  height: 70%;
  margin-top: 50px;
  border-radius: 5px;
}
/* contact */
.info {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icon {
  color: white;
  padding: 25px;
  border-radius: 50%;
  font-size: 25px;
  background-color: rgb(50, 50, 101);
}
.para :nth-child(1) {
  color: rgb(61, 61, 164);
  font-weight: bold;
}
/* services */

.cards {
  cursor: pointer;
  display: flex !important;
  border: none !important;
  gap: 10px;
  justify-content: space-between;
}
.home_service_card {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.home_service_card div {
  display: flex;
  flex-direction: column;
  line-height: 5px;
}

.cross {
  position: absolute;
  margin-left: 90%;
  margin-top: -45px;
  padding: 5px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  border-radius: 50%;
  background-color: white;
}
.ar_cross {
  position: absolute;
  margin-right: 90%;
  margin-top: -45px;
  padding: 5px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  border-radius: 50%;
  background-color: white;
}
.xmark {
  position: absolute;
}

/* testimonials */
.testimonials-heading {
  font-size: 45px;
}
.tesimonial-user-icon {
  font-size: 80px;
  color: rgb(48, 48, 117);
}
.tesimonial-quotes-icon {
  font-size: 100px;
  color: rgb(201, 201, 225);
}
.testimonials-para {
  margin-bottom: 60px;
}
.carousel-indicators li {
  font-size: 50px;
}
/* footer */
.footer {
  background-color: #111;
}
.footer-bottom {
  background-color: #333;
  padding-top: 5px;
}
.footer-topics {
  color: #929291;
  font-size: 20px;
}

/*Header CSS*/
.header {
  display: flex;
  padding: 15px 0;
}
.search_icon {
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  cursor: pointer;
}
.ar_search_icon {
  position: absolute;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.search_input {
  padding-left: 15px;
  cursor: pointer;
}
.search_input::placeholder {
  position: absolute;
  margin-left: 20px;
}
#en_left {
  padding-left: 35px;
}
#en_left::placeholder {
  margin-left: 5px;
}
#ar_right {
  padding-right: 35px;
}
#ar_right::placeholder {
  margin-right: 5px;
}
.location_button {
  border: 1px solid rgb(222, 216, 216) !important;
  border-radius: 5px;
}
.location_button:hover {
  background-color: #fff !important;
}

.login_btn {
  border: 1px solid rgb(222, 216, 216) !important;
  border-radius: 5px;
}
.country_btn {
  border: 1px solid rgb(222, 216, 216) !important;
  border-radius: 5px;
}
.logout {
  border: 0;
  font-size: 20px;
  color: rgb(48, 48, 117);
  appearance: none;
}
.ar_logout {
  border: 0;
  font-size: 20px;
  color: rgb(48, 48, 117);
  appearance: none;
}
#logout_op {
  width: 10px;
  position: absolute;
  top: 24px !important;
}

.myprofile {
  display: flex;
  gap: 20px;
  align-items: center;
}
.name_mobile {
  display: flex;
  flex-direction: column;
  line-height: 5px;
}
.profile_option {
  padding: 5px;
  cursor: pointer;
}
.ar_md_logout,
.md_logout,
.lg_user,
.md_userLogin,
.ar_md_userLogin,
.ar_md_user,
.md_search_icon,
.md_cart_icon {
  display: none;
}

.cart-count,
.md_item_count {
  position: absolute;
  background: red;
  top: -2px;
  right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  z-index: 11;
  cursor: pointer;
}
.ar_cart-count,
.ar_md_item_count {
  position: absolute;
  background: red;
  top: -2px;
  left: 11px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  z-index: 11;
  cursor: pointer;
}

#hide {
  display: none;
}
/* .location-controls */
.location-controls {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  width: 8.5rem;
  position: relative;
}
.location-controls button {
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  width: 100%;
  padding: 0.4rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.loc-container {
  width: 8rem;
  position: absolute;
  top: 2.5rem;
  border-radius: 0.3rem !important;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
.ar_loc-container {
  width: 8rem;
  position: absolute;
  top: 2.5rem;
  border-radius: 0.3rem !important;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
.dropdown-loc p {
  margin: 0;
  padding: 0.3rem 0;
}
.dropdown-loc {
  width: 100%;
  padding: 0.3rem 0.4rem;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-bottom: 1px dotted #9f9f9f;
}

.dropdown-loc:last-child {
  border: 0;
}
.options-loc {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.left_loc-align {
  left: 1rem;
}
.right_loc-align {
  right: 1rem;
}
.md_location-controls {
  display: none;
}
/* country language dropdown-list */
.form-controls {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  width: 5.5rem;
  position: relative;
}

.form-controls button {
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  width: 100%;
  padding: 0.34rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.form-controls button img,
.dropdown-list img {
  width: 1.5rem;
  height: 1rem;
}

.options-container {
  width: 5.5rem;
  position: absolute;
  top: 2.4rem;
  border-radius: 0.3rem !important;
  overflow: hidden;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
.ar_options-container {
  width: 5.5rem;
  position: absolute;
  top: 2.4rem;
  border-radius: 0.3rem !important;
  overflow: hidden;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.options-lists {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.15rem;
}

.dropdown-list {
  width: 100%;
  padding: 0.3rem 0.4rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  position: relative;
  cursor: pointer;
}

.dropdown-list:hover {
  background-color: #e7f5ff;
}

.dropdown-list p {
  position: absolute;
  top: 0;
}

.left-align {
  left: 2.4rem;
}

.right-align {
  right: 2.4rem;
}

.select-dropdown-icon {
  font-size: 0.8rem;
}

.globe-image,
.ar_md_search_icon {
  display: none;
}

/* vendors */
.vendor-icon {
  font-size: 150px;
  cursor: pointer;
}
/* .vendor-icon */
.vendor-img img {
  width: 120px;
  height: 120px;
  border-radius: 10px;
}
.package_card {
  padding: 10px;

  margin-bottom: 10px;
}
.vendor_pkg_img {
  width: 200px;
}

.vendor_pkg_img img {
  border-radius: 10px;
  width: 100%;
}
.vendor_details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
  padding: 20px;
}
.vendor_img {
  width: 120px;
  height: 120px;
}
.vendor_img img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.vendor_review {
  display: flex;
  align-items: center;
  gap: 50px;
}
.vendor_star {
  display: flex;
  align-items: center;
}
.vendor_review p i {
  font-size: 20px;
}
/* //.cart */
.cart_name {
  margin-left: 50%;
}
.checkout_p {
  display: flex;
  justify-content: space-between;
}
.search_field {
  position: relative;
}
.serched_packages {
  position: absolute;
  background-color: #f8f9fa;
  top: 40px;
  width: 93%;
  border-radius: 5px;
}
.searched_items {
  padding: 5px;
  margin-top: 5px;
  line-height: 5px;
  cursor: pointer;
}
.cart_head {
  display: flex !important;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px dotted #bfd5cf;
  color: rgb(48, 48, 117);
}

/* packages cart icon  */
.cart_icon {
  position: absolute;
  font-size: 40px;
  top: -10px;
  color: rgb(48, 48, 117);
  left: 40%;
  cursor: pointer;
}
.package_cart-count {
  position: absolute;
  background: red;
  top: -20px;
  right: 42%;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  z-index: 1;
  cursor: pointer;
}
.ar_package_cart-count {
  position: absolute;
  background: red;
  top: -20px;
  left: 42%;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  z-index: 11;
}
.cart_btn button {
  width: 120px;
}
.cart_block {
  padding: 20px;
  position: sticky;
}

.hide_cart_title {
  display: none;
}
.show_cart_title {
  display: block;
  padding-top: 30px !important;
}

.hide_cart {
  display: none;
}
.show_cart {
  display: flex;
  align-items: center;
  justify-content: center;
}
.carts_body {
  width: 570px !important;
}

.hide_empty_cart {
  display: none;
}
.show_empty_cart {
  padding: 30px;
  display: block;
  text-align: center;
}
.vendor_pkg_img {
  height: 150px;
}
.vendor_pkg_img img {
  height: 150px;
}

.reset_pass {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.reset_pass form {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.input-controls {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.checkout {
  display: flex;
  justify-content: space-around;
}
.checkout_box1 {
  width: 400px !important;
  max-height: 550px;
  overflow: scroll !important;
}
.checkout_box1::-webkit-scrollbar {
  width: 0 !important;
}
.checkout_box2 {
  width: 400px !important;
  max-height: 550px;
}
.checkout_cart {
  padding: 15px;
  overflow: scroll !important;
  height: 250px;
}

.checkout_cart::-webkit-scrollbar {
  width: 0 !important;
}
.serive_timing {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.serive_timing input {
  border: none;
}
.hideform {
  display: none;
}
.address_dropdown {
  text-align: left !important;
}
.ar_md_cart_icon {
  display: none;
}
.err-msg {
  font-size: 12px;
  color: red;
}
.hide_btn {
  display: none;
}
.show_btn {
  display: block;
}
.added_to_cart {
  background-color: #d7d7d3 !important;
}
.added_to_cart:hover {
  cursor: not-allowed;
}

/* my bookings */

.parent {
  display: flex;
  width: 100%;
  justify-content: center !important;
}
.mybookings {
  display: flex;
  flex-direction: column;
  width: 350px;
  justify-content: center;
  gap: 15px;
}
.booking_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.arr_head {
  display: flex;
  gap: 10px;
  align-items: center;
}
.reviews {
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 14px;
}
.reviews .rev {
  display: flex;
  gap: 5px;
  font-size: 10px;
}
.bookings {
  display: flex;
  flex-direction: column;
  gap: 3px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.add {
  display: flex;
  gap: 7px;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}
.service_date {
  display: flex;
  gap: 7px;
  justify-content: start;
  align-items: center;
  font-size: 14px;
}
.back_btn {
  cursor: pointer;
}
.ref_num {
  display: flex;
  flex-direction: column;
  line-height: 10px;
  margin: 5px 0 -25px 0;
  font-size: 14px;
}
.help {
  color: rgb(103, 103, 220) !important;
  font-weight: bold !important;
  font-size: 14px !important;
}
.allbookings {
  line-height: 5px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.allbookings p {
  font-size: 14px;
}
.booking_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.booking-head {
  margin-bottom: -20px;
  text-align: center;
}
.booking_package {
  display: flex;
  justify-content: space-between;
  line-height: 10px;
  font-size: 14px;
}
/*  */
.ordered {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}
.ordered h1 {
  color: rgb(43, 139, 43);
}

.currency {
  font-weight: bold;
  color: rgb(48, 48, 117);
}
.trash {
  cursor: pointer;
}
.user_icon {
  font-size: 80px;
  margin-top: -8px;
}

/* media queries */

/*  */
@media (max-width: 992px) {
  .container {
    max-width: 100% !important;
  }
  .checkout {
    display: flex !important;
    gap: 30px;
  }
  .checkout_box1 {
    width: 100% !important;
  }
  .checkout_box2 {
    width: 100% !important;
  }
  .testimonials-page {
    display: flex;
    flex-direction: column;
  }

  .checkout {
    display: block;
  }
}
@media (max-width: 768px) {
  .md_search_model {
    position: absolute;
    top: 50px;
  }
  .md_cross {
    position: absolute;
    margin-left: 90%;
    margin-top: -45px;
    padding: 5px;
    top: 50px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-radius: 50%;
    background-color: white;
  }
  .ar_md_cross {
    position: absolute;
    margin-right: 90%;
    margin-top: -45px;
    padding: 5px;
    top: 50px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-radius: 50%;
    background-color: white;
  }
  #show {
    position: absolute;
    margin-top: -32px;
    margin-right: 20px;
  }
  #shows {
    position: absolute;
    margin-top: -25px;
  }
  .globe-image {
    display: block;
  }
  .checkout {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .cart {
    margin: 0 auto;
    width: 100%;
  }
  .cart_block {
    width: 300px;
  }
}

@media (max-width: 576px) {
  .my_acount {
    width: 300px !important;
    margin: auto !important;
    font-size: 14px;
  }
  .user_icon {
    font-size: 50px;
    margin-top: 3px;
  }
  .hero h2 {
    font-size: 85px !important;
  }
  .carts_body {
    max-width: 350px !important;
    font-size: 10px !important;
  }

  .checkout_box2 {
    font-size: 12px;
  }
  .cart_btn {
    font-size: 10px !important;
  }

  .md_cart_icon,
  .ar_md_cart_icon {
    margin-top: 20px;
  }
  .md_package h5,
  p {
    font-size: small;
  }
  .vendor-img img {
    height: 90px;
    width: 90px;
  }
  .cart_btn button {
    width: 80px;
  }
  .pkg_dtl {
    font-size: 25px;
  }
  .package_card {
    width: 100%;
  }
  .en_cart {
    font-size: 18px;
    top: -55px;
    left: 302%;
  }

  .ar_cart {
    font-size: 18px;
    top: -55px;
    right: 302%;
  }
  .cart-count,
  .ar_cart-count {
    top: -75px;
    width: 15px;
    height: 15px;
    font-size: 10px;
  }
  .cart {
    margin: 0 auto;
    width: 100% !important;
  }
  .cart_block {
    width: 280px;
  }
  .serched_packages {
    top: 87px;
    width: 100%;
  }
}
