@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 1.7rem !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}
.heading {
  font-size: 20px;
  color: #303075;
}
.sub-heading {
  font-size: 18px;
  color: #303075;
}
html {
  scroll-behavior: smooth;
}
a:link {
  text-decoration: none;
}

a {
  color: #12b886 !important;
}
.dropdown-toggle::after {
  content: none !important;
}
.accordion-item {
  border: none !important;
}

/* for form validation  */
.err_msg {
  display: none;
  color: #ff8787;
  font-size: 12px;
  margin-top: -10px;
  padding-bottom: 5px;
}

input.input-error {
  border: 2px solid #ff8787;
  background: #ffc9c9;
}
.small-text {
  font-size: 12px;
  color: rgb(48, 48, 117);
}
.error {
  color: #ff8787;
  font-size: 12px;
  margin-top: -15px;
  padding-bottom: 5px;
}

/* START FROM HERE */

[data-star] {
  text-align: left;
  font-style: normal;
  display: inline-block;
  position: relative;
  unicode-bidi: bidi-override;
}
[data-star]::before {
  display: block;
  content: "★★★★★";
  color: #d6cfcf;
}
[data-star]::after {
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  content: "★★★★★";
  width: 0;
  color: #ff8c00;
  overflow: hidden;
  height: 100%;
}

[data-star^="0.5"]::after {
  width: 10%;
}
[data-star^="1"]::after {
  width: 20%;
}
[data-star^="1.5"]::after {
  width: 30%;
}
[data-star^="2"]::after {
  width: 40%;
}
[data-star^="2.5"]::after {
  width: 50%;
}
[data-star^="3"]::after {
  width: 60%;
}
[data-star^="3.5"]::after {
  width: 70%;
}
[data-star^="4"]::after {
  width: 80%;
}
[data-star^="4.5"]::after {
  width: 90%;
}
[data-star^="5"]::after {
  width: 100%;
}
